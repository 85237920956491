import hashHexColor from '../../modules/hashHexColor'

export default function getPrimaryColor(state) {
  const { customer } = state
  const {
    type,
    premium,
    design,
  } = customer

  if (type === 'brugger') {
    return '#F85A69'
  }

  if (!premium || !(design?.color)) {
    return null
  }

  return hashHexColor(design.color)
}
