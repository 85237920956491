import { Trans } from '@lingui/react'
import React from 'react'
import infowijsFooter from '../images/infowijs-footer.svg'

function InfowijsFooter() {
  return (
    <div className="absolute bottom-0 w-full h-20 flex flex-col items-center justify-center">
      <a href="https://infowijs.nl">
        <img
          src={infowijsFooter}
          alt="Infowijs"
          className="transform scale-50"
        />
      </a>
      <a
        href="https://infowijs.nl/juridisch"
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 text-sm"
        aria-label="Hoy privacy"
      >
        <Trans id="common.privacy" />
      </a>
    </div>
  )
}

export default InfowijsFooter
