import {
  put,
  retry,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import { i18n } from '@lingui/core'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import actionTypes from '../action-types'

import setCustomerMeta from '../actions/customer/setCustomerMeta'

const onRequestCustomerProductMeta = function* onRequestCustomerProductMeta() {
  yield takeLatest(actionTypes.CUSTOMER.REQUEST_META, function* requestMeta(request) {
    let { productType } = request.payload
    if (!productType) {
      productType = 'hoy'
    }
    let response
    try {
      response = yield retry(2, 1000, axios, {
        url: `${serviceEndpoints.customers}/products/${productType}/${request.payload.customerProduct}`,
        method: 'GET',
        headers: {
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      rejectAction(request, e)
      return
    }

    yield put(setCustomerMeta({
      ...response.data.data,
      type: productType,
    }))
    if (response.data.data && response.data.data.language) {
      i18n.activate(response.data.data.language)
    }
    acceptAction(request, response.data.data)
  })
}

export default onRequestCustomerProductMeta
