import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import { t } from '@lingui/macro'

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../action-types'
import signOut from '../actions/authentication/signOut'
import setSessionRequest from '../actions/authentication/setSessionRequest'

import setErrorMessage from '../actions/authentication/setErrorMessage'

const onSignIn = function* onSignIn() {
  yield takeLatest(actionTypes.AUTHENTICATION.SIGN_IN, function* signIn({ payload }) {
    // Erase any error message from previous tries
    yield put(setErrorMessage(null))

    const productType = payload.type ?? 'hoy'

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.sessions}`,
        method: 'POST',
        headers: {
          'X-Infowijs-Client': `nl.infowijs.${productType}.web/${payload.communityName}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          ...payload,
        },
      })
    } catch (e) {
      const defaultError = t({ id: 'error.unknown-login-error' })
      if (!e.response || !e.response.status) {
        yield put(setErrorMessage(defaultError))
        return
      }

      switch (e.response.status) {
        case 401:
          yield put(signOut())
          break
        case 404:
          yield put(setErrorMessage(t({ id: 'error.unknown-email' })))
          break
        default:
          yield put(setErrorMessage(defaultError))
          break
      }
      return
    }

    if (!response || !response.data) {
      return
    }

    yield put(setSessionRequest(response.data.data))
  })
}

export default onSignIn
