import getBruggerAdminClientAddress from '@Infowijs-eng/component-library/modules/getBruggerAdminClientAddress'
import { getHoyClientForUserPermissions } from '@Infowijs-eng/component-library/modules'

export default function getRedirectUrlForCustomerProduct(
  productType: string,
  productName: string,
  userPermissions: string[] = [],
) {
  switch (productType) {
    case 'hoy':
      return getHoyClientForUserPermissions(productName, userPermissions)
    case 'brugger':
      return getBruggerAdminClientAddress(productName)
    default:
      return null
  }
}
